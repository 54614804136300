import React, {useState, useEffect} from 'react';
import {SplitPane} from 'react-collapse-pane';
import {useParams} from 'react-router-dom';
import Scanner from '../Components/Scanner/Scanner';
import ScannerHeader from '../Components/Header/ScannerHeader';
import AdModal from '../Components/Modal/AdModal';
import Chart from '../Components/Chart/Chart';

const useReformattedGroups = (groupCollection, allGroups) => {
    if (!allGroups?.length || !Object.keys(groupCollection ?? {})?.length) return null;
    if (allGroups?.length < 2) return allGroups;
    return allGroups.map((gr, index) => {
      if (index === 1) {
        return {
          group: groupCollection[gr?.group],
          child: groupCollection[allGroups[index + 1]?.group] ?? null,
        };
      }
      if (index !== 2) {
        return {group: groupCollection[gr?.group]};
      }
      return null;
    });
  };


const DisplayScanners = ({
  groupCollection,
  groupCollectionKeys,
  showSideBySide,
  allGroups,
  dispatch,
  theme,
  setTheme,
  wsSocket,
  scannerData,
}) => {
    if (!groupCollectionKeys.length) return null;

    const [sideBySide, setSideBySide] = useState(showSideBySide);
    const [reformattedGroups, setFormattedGroups] = useState(useReformattedGroups(groupCollection, allGroups));

    useEffect(() => {
        if (showSideBySide !== sideBySide) {
            setSideBySide(showSideBySide);
        }
    }, [showSideBySide]);

    useEffect(() => {
        const newReFormatted = useReformattedGroups(groupCollection, allGroups);
        if (JSON.stringify(reformattedGroups) !== JSON.stringify(newReFormatted)) {
            setFormattedGroups(newReFormatted);
        }
    }, [groupCollection, allGroups]);

  if (!showSideBySide) {
    if (!reformattedGroups?.length) return null;
    return reformattedGroups
      .filter((item) => item !== null)
      .map((group) => {
        if (group?.child) {
          return (
            <SplitPane
              key={groupCollection[group?.group] ?? null}
              split="horizontal"
              collapse
              minSize={0}
              defaultSize={0}
            >
              <div
                className={`${
                  groupCollectionKeys.length > 1 ? 'multi-screen-scanner scanner-group' : ' scanner-group'
                }`}
                key={groupCollection[group?.group?.group] ?? null}
              >
                <Scanner
                  group={groupCollection[group?.group?.group] ?? null}
                  dispatch={dispatch}
                  multiScreen
                  hasChild
                  theme={theme}
                  setTheme={setTheme}
                  wsSocket={wsSocket}
                  noDataMessage={
                    scannerData?.noDataMessage ?? 'No Data Available'
                  }
                />
              </div>
              <div
                className={`${
                  groupCollectionKeys.length > 1 ? 'multi-screen-scanner scanner-group' : ' scanner-group'
                }`}
                key={groupCollection[group?.child] ?? null}
              >
                <Scanner
                  group={groupCollection[group?.child?.group] ?? null}
                  groupNumber={allGroups.length}
                  dispatch={dispatch}
                  multiScreen
                  hasChild
                  theme={theme}
                  setTheme={setTheme}
                  wsSocket={wsSocket}
                  noDataMessage={
                    scannerData?.noDataMessage ?? 'No Data Available'
                  }
                />
              </div>
            </SplitPane>
          );
        }
        return (
          <div
            className={`${groupCollectionKeys.length > 1 ? 'multi-screen-scanner scanner-group' : ' scanner-group'}`}
            key={groupCollection[group?.group?.group] ?? null}
          >
            <Scanner
              group={groupCollection[group?.group?.group] ?? null}
              groupNumber={allGroups.length}
              dispatch={dispatch}
              multiScreen
              wsSocket={wsSocket}
              noDataMessage={
                scannerData?.noDataMessage ?? 'No Data Available'
              }
            />
            <div className="clearfix" />
          </div>
        );
      });
  }
  if (!allGroups?.length) return null;

  return allGroups.map((group) => (
    <div
      className={`${groupCollectionKeys.length > 1 ? 'multi-screen-scanner scanner-group' : ' scanner-group'}`}
      key={group}
    >
      <Scanner
        group={groupCollection[group.group] ?? null}
        groupNumber={allGroups.length}
        dispatch={dispatch}
        multiScreen={groupCollectionKeys.length > 1}
        theme={theme}
        setTheme={setTheme}
        wsSocket={wsSocket}
        noDataMessage={
          scannerData?.noDataMessage ?? 'No Data Available'
        }
      />
      <div className="clearfix" />
    </div>
  ));
};

const Default = ({
  scannersData,
  groupCollection,
  groups,
  dispatch,
  theme,
  setTheme,
  wsSocket,
  loadingGroups,
  modalShow,
  handleClose,
  userData,
  showChart,
  closeChart,
  symbolData,
  chartSettings,
  getSymbolData,
  symbolToChart,
}) => {
  const [allGroups, setAllGroups] = useState([]);
  const [groupCollectionKeys, setGroupCollectionKeys] = useState(Object.keys(groupCollection || {}));
  const [scanners, setScanners] = useState(null);
  const params = useParams();
  const scannerId = params?.id;

  const scannerData = scannersData?.find((s) => s.slug === scannerId);
  const scannerTitle = scannerData?.title;
//   const urlSearchParams = new URLSearchParams(window.location.search);
  const sideBySide = scannerData ? scannerData.sideBySide : false;
  const showSideBySide =
    document.getElementById('scan_scanner').getAttribute('data-side-by-side') ?? sideBySide ?? false;

  useEffect(() => {
    if (groups && groups?.length) {
      const newGroups = [
        ...groups
          .reduce((a, c) => {
            a.set(c?.group, c);
            return a;
          }, new Map())
          .values(),
      ];
      setAllGroups(newGroups);
    }
  }, [groups]);



  useEffect(() => {
    if (!groupCollection) return;
    setGroupCollectionKeys(Object.keys(groupCollection || {}));
  }, [groupCollection]);

  useEffect(() => {
    if (!groupCollectionKeys.length) return;
    setScanners(allGroups.filter((item) => item).map((group) => group.group));
  }, [allGroups, showSideBySide]);

  if (!groupCollection || !groupCollectionKeys?.length || loadingGroups || !scanners?.length) {
    return null;
  }

  if (scanners && scanners?.length > 1) {
    return (
      <>
        <AdModal open={modalShow} handleClose={handleClose} freeUser={userData?.free_access} />
        {showChart ? (
          <Chart
            chartSettings={chartSettings}
            symbolData={symbolData}
            symbolToChart={symbolToChart}
            closeChart={closeChart}
            getSymbolData={getSymbolData}
          />
        ) : null}

        <ScannerHeader
          name={scannerTitle}
          theme={theme}
          setTheme={setTheme}
          userData={userData}
          showChart={showChart}
        />
        <SplitPane split="vertical" collapse minSize={0} defaultSize={0}>
          <DisplayScanners
            groupCollection={groupCollection}
            groupCollectionKeys={groupCollectionKeys}
            showSideBySide={showSideBySide}
            useReformattedGroups={useReformattedGroups}
            allGroups={allGroups}
            dispatch={dispatch}
            theme={theme}
            setTheme={setTheme}
            wsSocket={wsSocket}
            scannerData={scannerData}
          />
        </SplitPane>
      </>
    );
  }

  if (scanners && scanners?.length === 1) {
    return (
      <>
        <AdModal open={modalShow} handleClose={handleClose} freeUser={userData?.free_access} />
        {showChart ? (
          <Chart
            chartSettings={chartSettings}
            symbolData={symbolData}
            symbolToChart={symbolToChart}
            closeChart={closeChart}
            getSymbolData={getSymbolData}
          />
        ) : null}
        <ScannerHeader
          name={scannerTitle}
          theme={theme}
          setTheme={setTheme}
          userData={userData}
          showChart={showChart}
          groupCollection={groupCollection}
          dispatch={dispatch}
        />
        <DisplayScanners
          groupCollection={groupCollection}
          groupCollectionKeys={groupCollectionKeys}
          showSideBySide={showSideBySide}
          useReformattedGroups={useReformattedGroups}
          allGroups={allGroups}
          dispatch={dispatch}
          theme={theme}
          setTheme={setTheme}
          wsSocket={wsSocket}
          scannerData={scannerData}
        />
      </>
    );
  }
  return null;
};

export default Default;
